var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('HeaderOnePageTwo'),_c('div',{staticClass:"rn-blog-details pt--110 pb--70 bg_color--1"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"inner-wrapper"},[_c('div',{staticClass:"inner"})])])],1)],1)],1),_c('div',{staticClass:"blog-comment-form pb--120 bg_color--1"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"inner"},[_c('div',{staticClass:"section-title"},[_c('h3',{staticClass:"heading-title"},[_vm._v(" Please share your thoughts on HEU's Homepage. ")])]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"mt--40",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('v-row',[_c('v-col',[_c('div',{staticClass:"rnform-group"},[_vm._v(" Name "),_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.name),expression:"formData.name"}],attrs:{"type":"text"},domProps:{"value":(_vm.formData.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "name", $event.target.value)}}}),_c('span',{staticClass:"inpur-error"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('div',{staticClass:"rnform-group"},[_c('p',[_vm._v("On a scale of 1 - 7, how appealing is the HEU homepage? (7 - Very Appealing and 1 - Unappealing) ")]),_c('ValidationProvider',{attrs:{"name":"question1","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.question1),expression:"formData.question1"}],attrs:{"type":"text","rules":"required|question1","placeholder":"7 - Very Appealing to 1 - Unappealing"},domProps:{"value":(_vm.formData.question1)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "question1", $event.target.value)}}}),_c('span',{staticClass:"inpur-error"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('div',{staticClass:"rnform-group"},[_c('p',[_vm._v("What is appealing about this site?")]),_c('ValidationProvider',{attrs:{"name":"question2","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.question2),expression:"formData.question2"}],attrs:{"placeholder":"Comment"},domProps:{"value":(_vm.formData.question2)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "question2", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"inpur-error"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('div',{staticClass:"rnform-group"},[_c('p',[_vm._v("What, if anything would make this page more appealing?")]),_c('ValidationProvider',{attrs:{"name":"question3","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.question3),expression:"formData.question3"}],attrs:{"placeholder":"Comment"},domProps:{"value":(_vm.formData.question3)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "question3", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"inpur-error"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('div',{staticClass:"rnform-group"},[_c('p',[_vm._v("Are there any additional comments or questions you have about HEU? ")]),_c('ValidationProvider',{attrs:{"name":"question4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.question4),expression:"formData.question4"}],attrs:{"placeholder":"Comment"},domProps:{"value":(_vm.formData.question4)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "question4", $event.target.value)}}}),_vm._v(" "),_c('span',{staticClass:"inpur-error"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)]),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"blog-submit-btn"},[_c('button',{staticClass:"btn-default",attrs:{"type":"submit","value":"submit"}},[_vm._v(" SUBMIT ")])])])],1)],1)]}}])})],1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }