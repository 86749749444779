import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyCjl_88aZtQLC3a4LHOIRwH0pdVbIlfpzI',
  authDomain: 'heuapp-67909.firebaseapp.com',
  projectId: 'heuapp-67909',
  storageBucket: 'heuapp-67909.appspot.com',
  messagingSenderId: '1081500341129',
  appId: '1:1081500341129:web:e35d5aff763e4aa3184758',
  measurementId: 'G-S9RQDBFQBX',
}

firebase.initializeApp(firebaseConfig)
const db = firebase.firestore()

db.settings({ timestampInSnapshots: true })
export default db
