<template>
  <div>
    <!-- Start Header Area -->
    <HeaderOnePageTwo />
    <!-- End Header Area -->
    <!-- Start Blog Details Area  -->
    <div class="rn-blog-details pt--110 pb--70 bg_color--1">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="inner-wrapper">
              <div class="inner" />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Blog Details Area  -->
    <!-- Start Comment Form  -->
    <div class="blog-comment-form pb--120 bg_color--1">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="inner">
              <div class="section-title">
                <h3 class="heading-title">
                  Please share your thoughts on HEU's Homepage.
                </h3>
              </div>
              <ValidationObserver v-slot="{ handleSubmit }">
                <form
                  class="mt--40"
                  @submit.prevent="handleSubmit(onSubmit)"
                >
                  <v-row>
                    <v-col>
                      <div class="rnform-group">
                        Name
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="name"
                          rules="required"
                        >
                          <label>
                            <input
                              v-model="formData.name"
                              type="text"
                            >
                            <span class="inpur-error">{{ errors[0] }}</span>
                          </label>
                        </ValidationProvider>
                      </div>

                      <div class="rnform-group">
                        <p>On a scale of 1 - 7, how appealing is the HEU homepage? (7 - Very Appealing and  1 - Unappealing) </p>
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="question1"
                          rules="required"
                        >
                          <label>
                            <input
                              v-model="formData.question1"
                              type="text"
                              rules="required|question1"
                              placeholder="7 - Very Appealing to 1 - Unappealing"
                            >
                            <span class="inpur-error">{{ errors[0] }}</span>
                          </label>
                        </ValidationProvider>
                      </div>

                      <div class="rnform-group">
                        <p>What is appealing about this site?</p>
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="question2"
                          rules="required"
                        >
                          <label>
                            <textarea
                              v-model="formData.question2"
                              placeholder="Comment"
                            />
                            <span class="inpur-error">{{ errors[0] }}</span>
                          </label>
                        </ValidationProvider>
                      </div>

                      <div class="rnform-group">
                        <p>What, if anything would make this page more appealing?</p>
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="question3"
                          rules="required"
                        >
                          <label>
                            <textarea
                              v-model="formData.question3"
                              placeholder="Comment"
                            />
                            <span class="inpur-error">{{ errors[0] }}</span>
                          </label>
                        </ValidationProvider>
                      </div>

                      <div class="rnform-group">
                        <p>Are there any additional comments or questions you have about HEU?  </p>
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="question4"
                        >
                          <label>
                            <textarea
                              v-model="formData.question4"
                              placeholder="Comment"
                            />
                            <span class="inpur-error">{{ errors[0] }}</span>
                          </label>
                        </ValidationProvider>
                      </div>
                    </v-col>

                    <v-col cols="12">
                      <div class="blog-submit-btn">
                        <button
                          class="btn-default"
                          type="submit"
                          value="submit"
                        >
                          SUBMIT
                        </button>
                      </div>
                    </v-col>
                  </v-row>
                </form>
              </ValidationObserver>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Comment Form  -->

  </div>
</template>

<script>
import feather from 'feather-icons'
import { ValidationObserver } from 'vee-validate'
import { ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm'
// import Footer from '../components/footer/Footer.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import db from '@/store/firestoreDB'
import HeaderOnePageTwo from '../components/header/HeaderOnePageTwo.vue'

export default {
  components: {
    HeaderOnePageTwo,
    // Footer,
    ValidationObserver,
    ValidationProvider,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      logo: require('../assets/images/logo/logo.png'),
      formData: {
        name: '',
        question1: '',
        question2: '',
        question3: '',
        question4: '',
      },
      items: [
        {
          // eslint-disable-next-line global-require
          thumb: require('../assets/images/blog/bl-big-01.jpg'),
          src: 'https://www.youtube.com/watch?v=ZOoVOfieAF8',
        },
      ],
      metaList: [
        {
          icon: 'clock',
          text: 'May 18, 2020',
        },
        {
          icon: 'user',
          text: 'Fatima',
        },
        {
          icon: 'message-circle',
          text: '15 Comments',
        },
        {
          icon: 'heart',
          text: 'Like',
        },
      ],
      index: null,
    }
  },

  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg()
    },
    onSubmit() {
      const diaryRecord = {
        name: this.formData.name,
        question1: this.formData.question1,
        question2: this.formData.question2,
        question3: this.formData.question3,
        question4: this.formData.question4,
      }
      this.$store.commit('addDiaryRecord', diaryRecord)
      db.collection('writtenDiaryRecord').add(diaryRecord).then(() => {

      })
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notification',
          icon: 'BellIcon',
          text: '👋 Thanks for the feedback',
          variant: 'primary',
        },
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/tour.scss';
// import core styles
@import  '@/assets/scss/style.scss';
</style>
