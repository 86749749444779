<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      class="hidden-md-and-up header-one-page-nav-drawer"
      fixed
      width="320"
    >
      <v-list-item class="pa-5">
        <div class="logo">
          <img
            src="../../assets/images/home/chat.png"
            alt="brand-image"
          >
        </div>
        <v-spacer />
        <v-btn
          class="close-icon"
          icon
          @click="drawer = !drawer"
          v-html="iconSvg(closeIcon)"
        />
      </v-list-item>

      <scrollactive
        active-class="v-btn--active"
        bezier-easing-value=".5,0,.35,1"
        :offset="70"
      >
        <v-list>
          <v-list-item
            v-for="item in items"
            :key="item.title"
            :ripple="false"
            :to="item.to"
            link
            class="scrollactive-item"
          >
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- End mobile menu sidebar item list -->
        </v-list>
      </scrollactive>
    </v-navigation-drawer>
    <!-- End sidebar mobile menu -->

    <v-app-bar
      dark
      color="transparent"
      fixed
      elevate-on-scroll
      class="header-one-page-nav header-one-page-nav-two"
    >
      <router-link
        to="/"
        class="logo"
      >
        <img src="../../assets/images/home/heubot_9.svg">
      </router-link>
      <!-- End brand logo -->

      <v-toolbar-items class="hidden-xs-only hidden-sm-only height-auto ml--70">
        <scrollactive
          active-class="v-btn--active"
          bezier-easing-value=".5,0,.35,1"
          :offset="71"
        >
          <v-btn
            v-for="item in items"
            :key="item.title"
            :to="item.to"
            link
            :ripple="false"
            text
            class="scrollactive-item"
          >{{ item.title }}</v-btn>
        </scrollactive>
      </v-toolbar-items>
      <!-- End header menu item -->
      <v-spacer />
      <div class="social-share-inner">
        <socialTwo />
      </div>
      <a
        class="btn-default btn-border btn-opacity"
        target="_blank"
        href="/app"
      >
        <span>Meet HEU</span>
      </a>
      <v-btn
        icon
        :ripple="false"
        class="ma-0 pa-0 hidden-md-and-up menu_icon"
        @click="drawer = !drawer"
        v-html="iconSvg(icon)"
      />
      <!-- End mobile menu icon -->
    </v-app-bar>
    <!-- End top header navbar -->
  </div>
</template>

<script>
import feather from 'feather-icons'
import socialTwo from '../social/SocialTwo.vue'

export default {
  components: {
    socialTwo,
  },
  data: () => ({
    drawer: false,
    items: [
      { title: 'Home', to: '/' },
      // { title: 'About', to: '/about' },
      // { title: 'Feedback', to: '/feedback' },
      { title: 'Privacy', to: '/privacy' },
      { title: 'Contact', to: '/contact' },
    ],
    icon: 'menu',
    closeIcon: 'x',
  }),
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg()
    },
  },
}
</script>
